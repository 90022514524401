$honey_flower: #572383;
$pelorous: #45b5c8;
$alabaster: #fbfbfb;
$mine_shaft: #323232;
$manatee: #8389a0;
$blue_charcoal: #000617;
$white: #ffffff;
$mirage: #141a2b;
$white_lilac: #ecf0f8;
$periwinkle: #c9cfe6;
$manatee: #8d93aa;
$link_water: #eef2fa;

// rgb
$mirage_rgba: rgba(20, 26, 43, 0.5);
$overlay: rgba(0, 6, 23, 0.8);
$white_lilac_rgba: rgba(236, 240, 248, 0.6);
$button_disabled: rgba(255, 255, 255, 0.3);
$link_after_active: rgba(69, 181, 200, 0.16);
$active_circle: rgba(69, 181, 200, 0.16);
$alabaster_rgba: rgba(131, 137, 160, 0.03);

// button ripple
$ripple-light: rgba(255, 255, 255, 0.3);
$ripple-gray: rgba(69, 181, 200, 0.16);
