.footer {
  background-color: $blue_charcoal;
  &__wrapper {
    padding: 30px 0 30px;
    @include media(">=tablet") {
      padding: 50px 0 50px;
    }
    @include media(">=big_desktop") {
      padding: 70px 0 70px;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @include media(">=big_desktop") {
      flex-wrap: nowrap;
    }
  }
  &__logo {
    margin-bottom: 27px;
    @include media(">=big_desktop") {
      margin-bottom: 0;
      margin-right: 260px;
    }
  }
  &__contacts {
    @include media(">=big_desktop") {
      margin-right: 30px;
    }
  }
  &__copyright {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    &-name,
    &-link {
      color: $alabaster;
      font-size: 14px;
      line-height: 16px;
      opacity: 0.3;
      flex-shrink: 0;
    }
    &-link {
      margin-left: 3px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.contacts-footer {
  margin-bottom: 20px;
  @include media(">=big_desktop") {
    margin-bottom: 0;
  }
  &__list {
    margin-bottom: 30px;
    @include media(">=tablet") {
      margin-bottom: 55px;
    }
    @include media(">=big_desktop") {
      margin-bottom: 70px;
    }
  }
  &__item {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    @include media(">=big_desktop") {
      margin-bottom: 25px;
    }
  }
  &__title {
    color: $alabaster;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    @include media(">=big_desktop") {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 5px;
    }
  }
  &__span {
    color: $pelorous;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    @include media(">=big_desktop") {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__text {
    color: $alabaster;
    font-size: 10px;
    line-height: 16px;
  }
  &__quality {
    max-width: 380px;
  }
}
