.angle-left {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 10px;
  transform: translate(-50%, -50%);
  fill: $white;
  @include media(">=tablet") {
    width: 7px;
    height: 13px;
  }
  @include media(">=big_desktop") {
    width: 10px;
    height: 18px;
  }
  &--right {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  &--top {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  &--bottom {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
}

.pdf {
  width: 26px;
  height: 30px;
  fill: transparent;
  @include media(">=tablet") {
    width: 35px;
    height: 40px;
  }
}

.up_svg {
  width: 32px;
  @include media(">=big_desktop") {
    width: 47px;
    height: 55px;
  }
}

.people_svg {
  width: 41px;
  @include media(">=big_desktop") {
    width: 57px;
    height: 37px;
  }
}

.mechanics_svg {
  width: 29px;
  @include media(">=big_desktop") {
    width: 44px;
    height: 50px;
  }
}

.product_svg {
  width: 30px;
  @include media(">=big_desktop") {
    width: 42px;
    height: 51px;
  }
}

.stock_svg {
  width: 40px;
  @include media(">=big_desktop") {
    width: 56px;
    height: 51px;
  }
}

.success_svg {
  width: 36px;
  @include media(">=big_desktop") {
    width: 50px;
    height: 50px;
  }
}

.logo_big_svg {
  width: 124px;
  height: 88px;
  @include media(">=tablet") {
    width: 173px;
    height: 123px;
  }
  @include media(">=big_desktop") {
    width: 258px;
    height: 183px;
  }
}

.iso {
  width: 60px;
  height: 57px;
  @include media(">=tablet") {
    width: 48px;
  }
  @include media(">=big_desktop") {
    width: 68px;
  }
}

.trace {
  width: 60px;
  height: 38px;
  @include media(">=tablet") {
    width: 46px;
  }
  @include media(">=big_desktop") {
    width: 64px;
  }
}
