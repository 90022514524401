.header {
  position: relative;
  background-color: $blue_charcoal;
  &__wrapper {
    padding: 70px 0 270px;
    @include media(">=tablet") {
      padding: 100px 0 550px;
    }
    @include media(">=big_desktop") {
      padding: 250px 0 235px;
    }
  }
  &__info {
    position: relative;
    z-index: 2;
    .circle {
      &::after {
        width: 7px;
        height: 7px;
        bottom: 22px;
        @include media(">=tablet") {
          width: 13px;
          height: 13px;
          bottom: 39px;
        }
        @include media(">=desktop") {
          bottom: 43px;
        }
      }
    }
    @include media(">=big_desktop") {
      max-width: 410px;
    }
  }
  &__title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    letter-spacing: -0.015em;
    margin-bottom: 15px;
    display: inline-block;
    @include media(">=tablet") {
      font-size: 72px;
      line-height: 80px;
    }
    @include media(">=big_desktop") {
      font-size: 80px;
      line-height: 88px;
      margin-bottom: 20px;
    }
  }
  &__text {
    color: $alabaster;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
    @include media(">=tablet") {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 35px;
    }
    @include media(">=big_desktop") {
      margin-bottom: 15px;
    }
  }
  &__content {
    position: relative;
  }
  &__background {
    position: absolute;
    top: 295px;
    left: -230px;
    z-index: 0;
    @include media(">=tablet") {
      top: 300px;
      left: -410px;
    }
    @include media(">=big_desktop") {
      top: -150px;
      left: 0px;
    }
  }
}

.planar_svg {
  width: 520px;
  height: 272px;
  @include media(">=tablet") {
    width: 970px;
    height: 556px;
  }
  @include media(">=big_desktop") {
    width: 1328px;
    height: 711px;
  }
}
