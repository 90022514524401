.capability {
  &__list {
    display: flex;
    flex-wrap: wrap;
    @include media(">=big_desktop") {
      flex-direction: row;
      margin-left: 104px;
      margin-top: 210px;
    }
  }
  &__item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    @include media(">=tablet") {
      margin-bottom: 38px;
      &:nth-child(2n) {
        margin-left: 65px;
      }
    }
    @include media(">=big_desktop") {
      &:nth-child(odd) {
        margin-top: -160px;
        padding-bottom: 160px;
      }
      margin-bottom: 53px;
      &:nth-child(odd) {
        margin-right: 115px;
      }
      &:nth-child(2n) {
        margin-left: 0;
      }
      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }
  }
}
.capability-block {
  margin: 0;
  max-width: 505px;
  &__image-wrapper {
    position: relative;
    width: 244px;
    height: 166px;
    margin-bottom: 25px;
    @include media(">=tablet") {
      width: 400px;
      height: 272px;
      margin-bottom: 30px;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &__counter {
    position: absolute;
    top: 120px;
    right: -15px;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $pelorous;
    @include media(">=tablet") {
      top: 190px;
      right: -17px;
      width: 48px;
      height: 48px;
    }
  }
  &__span {
    color: $white;
    font-size: 14px;
    line-height: 24px;
    @include media(">=tablet") {
      font-size: 16px;
    }
  }
  &__title {
    color: $manatee;
    font-style: italic;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 15px;
    @include media(">=tablet") {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
    @include media(">=tablet") {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  &__list {
    .capability-block__text {
      margin-bottom: 3px;
    }
  }
  &__item {
    color: $mine_shaft;
    font-size: 14px;
    line-height: 24px;
    @include media(">=tablet") {
      font-size: 16px;
    }
  }
}
