.control {
  background-color: $white_lilac_rgba;
  &__content {
    @include media(">=big_desktop") {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  &__left {
    @include media(">=big_desktop") {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .section__wrapper {
    @include media(">=big_desktop") {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -95%;
        width: 100%;
        height: 100%;
        background-color: $blue_charcoal;
      }
    }
  }
  .docs {
    display: block;
    border: 1px solid $periwinkle;
    background-color: $alabaster;
    padding: 20px;
    @include media('>=tablet') {
      padding: 20px 34px;
    }
    @include media(">=big_desktop") {
      max-width: 536px;
    }
    &__item {
      @include media(">=tablet") {
        &:first-child {
          margin-right: 0
        }
      }
      @include media(">=big_desktop") {
        margin-bottom: 0;
        &:first-child {
          margin-right: 37px;
        }
      }
    }
  }
}

.managements {
  width: 100%;
  &__item {
    display: flex;
    flex-direction: column;
    @include media(">=tablet") {
      flex-direction: row;
      align-items: flex-start;
    }
  }
  &__image {
    width: 80px;
    height: 80px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;
    border: 2px solid $manatee;
    border-radius: 50%;
    @include media(">=tablet") {
      width: 64px;
      height: 64px;
      margin-bottom: 0;
      margin-right: 24px;
    }
    @include media(">=big_desktop") {
      width: 88px;
      height: 88px;
      margin-right: 15px;
    }
  }
  &__item {
    margin-bottom: 15px;
    @include media(">=tablet") {
      margin-bottom: 50px;
    }
    @include media(">=big_desktop") {
      margin-bottom: 30px;
    }
  }
  &__text-wrapper {
    .managements__text {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      @include media(">=tablet") {
        margin-bottom: 20px;
      }
    }
  }
  &__text {
    font-size: 14px;
    line-height: 24px;
    @include media(">=tablet") {
      font-size: 16px;
    }
  }
}


.certificate {
  position: relative;
  bottom: 80px;
  display: none;
  @include media(">=big_desktop") {
    display: block;
  }
  &__background {
    &:first-child {
      z-index: 2;
    }
    &--big {
      width: 246px;
      height: 349px;
      background: $manatee url(../images/controls/certificate_big.jpg);
      background-size: cover;
    }
    &--small {
      position: absolute;
      bottom: -40px;
      right: 216px;
      width: 255px;
      height: 199px;
      background: $manatee url(../images/controls/certificate_small.jpg);
      background-size: cover;
    }
  }
}
