.tabs {
  &__tab {
    &-list {
      display: flex;
      margin-bottom: 35px;
      @include media(">=tablet") {
        margin-bottom: 45px;
      }
      @include media(">=big_desktop") {
        margin-bottom: 65px;
      }
    }
    &-item {
      @include media(">=big_desktop") {
        margin-right: 16px;
      }
      &:hover {
        .tabs__tab-button {
          border-color: $honey_flower;
        }
      }
      &--active {
        .tabs__tab-button {
          border-color: $honey_flower;
          &::after {
            content: "";
            position: absolute;
            right: 50%;
            bottom: -16px;
            height: 32px;
            width: 1px;
            background-color: $honey_flower;
            transform: translateX(-50%);
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &:nth-child(1) {
        .tabs__tab-img {
          width: 146px;
          height: 30px;
        }
      }
      &:nth-child(2) {
        .tabs__tab-img {
          width: 112px;
          height: 60px;
        }
      }
      &:nth-child(3) {
        .tabs__tab-img {
          width: 130px;
          height: 37px;
        }
      }
      &:nth-child(4) {
        .tabs__tab-img {
          width: 145px;
          height: 53px;
        }
      }
    }
    &-button {
      position: relative;
      padding: 0;
      cursor: pointer;
      transition: 0.15s;
    }
  }
  &__content {
    &-list {
      min-height: 675px;
      @include media(">=tablet") {
        min-height: 437px;
      }
      @include media(">=big_desktop") {
        min-height: 315px;
        max-width: 1025px;
      }
    }
    &-item {
      display: none;
      &--active {
        display: block;
      }
    }
    &-text {
      color: $mine_shaft;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 15px;
      @include media(">=tablet") {
        font-size: 16px;
        margin-bottom: 23px;
      }
      @include media(">=tablet") {
        margin-bottom: 27px;
      }
    }
    &-title {
      color: $mine_shaft;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 15px;
      @include media(">=tablet") {
        margin-bottom: 20px;
      }
      @include media(">=big_desktop") {
        margin-bottom: 15px;
      }
    }
    &-text-list {
      margin-bottom: 20px;
      @include media(">=tablet") {
        margin-bottom: 30px;
      }
      .tabs__content-text {
        margin-bottom: 0;
      }
    }
    &-link {
      &::after {
        bottom: 6px;
      }
    }
  }
  .tns-ovh {
    overflow: visible;
  }
}
