.section {
  &__wrapper {
    position: relative;
    padding: 36px 0;
    z-index: 1;
    @include media(">=tablet") {
      padding: 64px 0;
    }
    @include media(">=big_desktop") {
      padding: 104px 0;
    }
  }
  &__header {
    margin-bottom: 30px;
    @include media(">=tablet") {
      margin-bottom: 40px;
    }
    @include media(">=big_desktop") {
      max-width: 720px;
      margin-bottom: 50px;
    }
  }
  &__title {
    color: $mine_shaft;
    font-size: 28px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 22px;
    display: inline-block;
    @include media(">=tablet") {
      font-size: 40px;
      line-height: 42px;
      margin-bottom: 30px;
    }
    @include media(">=big_desktop") {
      font-size: 56px;
      line-height: 64px;
      letter-spacing: -0.005em;
      margin-bottom: 10px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 35px;
    @include media(">=tablet") {
      font-size: 16px;
      margin-bottom: 25px;
    }
    @include media(">=big_desktop") {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}
