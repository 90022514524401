#docs {
  background-color: $white_lilac_rgba;
}
.docs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0 ;
  @include media('>=big_desktop') {
    padding: 20px;
  }
  &__list {
    width: 100%;
    @include media(">=tablet") {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    @include media('>=big_desktop') {
      width: auto;
    }
  }
  &__item {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
    @include media(">=tablet") {
      margin-bottom: 0;
      &:first-child {
        margin-right: 37px;
      }
    }
    @include media(">=big_desktop") {
    }
  }
  &__icon {
    height: 40px;
    margin-right: 15px;
  }
  &__size {
    display: block;
    color: $manatee;
    font-size: 10px;
    line-height: 16px;
  }
}