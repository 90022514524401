.slider {
  &__controls {
    position: absolute;
    right: 0;
    bottom: 35px;
    display: flex;
    @include media(">=tablet") {
      bottom: 73px;
    }
    @include media(">=big_desktop") {
      bottom: 80px;
    }
    &-wrapper {
      position: relative;
    }
  }
  &__button {
    position: relative;
    cursor: pointer;
    &--prev,
    &--next {
      width: 48px;
      height: 48px;
      background-color: $honey_flower;
      border: 0;
      @include media(">=big_desktop") {
        width: 64px;
        height: 64px;
      }
    }
  }
}
