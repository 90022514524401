.burger {
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  z-index: 2;
  transition: 0.2s;
  @include media(">=tablet") {
    width: 80px;
    height: 80px;
  }
  @include media(">=big_desktop") {
    display: none;
  }
  &__container {
    position: relative;
    width: 18px;
    height: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include media(">=tablet") {
      height: 20px;
    }
  }
  &__line {
    width: 100%;
    height: 0.156em;
    background-color: $pelorous;
    transition: 0.2s;
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.156em;
      background-color: $pelorous;
      transition: 0.2s;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
  }

  &--close {
    background-color: $pelorous;
    .burger {
      &__line {
        width: 100%;
        height: 2px;
        background-color: $blue_charcoal;
        background-color: transparent;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          width: 100%;
          height: 2px;
          background-color: $blue_charcoal;
        }
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
