@font-face {
  font-family: "Gotham Pro";
  src: url("../../fonts/GothamPro/GothamPro-Light.woff") format("woff");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../fonts/GothamPro/GothamPro.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../fonts/GothamPro/GothamPro-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../fonts/GothamPro/GothamPro-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
