.portal {
  position: fixed;
  top: 65%;
  left: 50%;
  width: 100%;
  height: 100vh;
  transform: translate(-50%, -50%);
  background-color: $white;
  box-shadow: 0px 2px 10px rgba(25, 33, 51, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  padding: 0;
  border: 0;
  z-index: 999;
  overflow-y: scroll;
  @include media(">=big_desktop") {
    width: 815px;
    height: auto;
    overflow-y: hidden;
  }
  &--visible {
    top: 50%;
    opacity: 1;
    visibility: visible;
  }
  &__wrapper {
    padding: 35px 32px 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media(">=big_desktop") {
      height: 100%;
      padding: 65px 104px 70px;
    }
  }
  &__title {
    color: $mine_shaft;
    font-size: 16px;
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 35px;
    @include media(">=tablet") {
      margin-bottom: 60px;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
    }
    @include media(">=big_desktop") {
      margin-bottom: 65px;
    }
  }
  .burger {
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 56px;
    display: flex;
    background-color: $honey_flower;
    @include media(">=big_desktop") {
      width: 64px;
      height: 64px;
    }
    &--close {
      .burger__line {
        &::before,
        &::after {
          background-color: $white;
        }
      }
    }
  }
}

.form {
  transition: 0.12s;
  &--hidden {
    opacity: 0;
    visibility: hidden;
  }
  &__main {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    @include media(">=big_desktop") {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 30px;
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media(">=big_desktop") {
      margin-right: 16px;
    }
    .form__field {
      margin-bottom: 25px;
      @include media(">=big_desktop") {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__right {
    .form__field {
      height: 100%;
    }
  }
  &__field {
    position: relative;
    width: 100%;
    min-height: 48px;
    border-radius: 1px;
  }
  &__label {
    color: $manatee;
    position: absolute;
    top: 50%;
    left: 20px;
    font-size: 14px;
    line-height: 16px;
    transform: translateY(-50%);
    transform-origin: 0 100%;
    transition: 0.15s;
  }
  &__input,
  &__textarea {
    color: $mine_shaft;
    font-family: "Gotham Pro", sans-serif;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    height: 48px;
    padding: 20px 20px 10px;
    border: 0;
    background-color: $white_lilac;
    &.focus {
      & + .form__label {
        top: 3px;
        transform: translateY(0) scale(0.8);
      }
    }
    @include media(">=tablet") {
      width: 560px;
    }
    @include media(">=big_desktop") {
      width: 296px;
      height: 100%;
    }
  }
  &__textarea {
    resize: none;
    height: 184px;
    @include media(">=big_desktop") {
      height: 100%;
    }
    & + .form__label {
      top: 16px;
      transform: translateY(0);
    }
  }
  &__text {
    color: $mine_shaft;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 20px;
    @include media(">=big_desktop") {
      margin-bottom: 25px;
    }
    @include media(">=big_desktop") {
      margin-bottom: 40px;
    }
  }
  &__button {
    width: 100%;
    text-align: center;
  }
  &-success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $mine_shaft;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    transition: 0.15s;
    &--visible {
      opacity: 1;
      visibility: visible;
    }
  }
}
