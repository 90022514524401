.distributor {
  background-color: $white_lilac_rgba;
  overflow: hidden;
  &__wrapper {
    display: flex;
    flex-direction: column;
    @include media(">=big_desktop") {
      flex-direction: row;
    }
  }
  &__left,
  &__right {
    @include media(">=big_desktop") {
      width: 50%;
    }
  }
  &__left {
    @include media(">=big_desktop") {
      width: calc(50% - 14px);
      margin-right: 14px;
    }
  }
  &__text {
    @include media(">=big_desktop") {
      max-width: 510px;
    }
  }
  &__list-wrapper {
    position: relative;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    @include media(">=big_desktop") {
      position: static;
      left: 0;
      width: 100%;
      margin-left: 0;
      overflow-x: hidden;
      .container {
        padding: 0;
      }
    }
  }
  &__list {
    @include media(">=big_desktop") {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__item {
    display: inline-block;
    @include media(">=big_desktop") {
      margin-right: 16px;
      margin-bottom: 16px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  &__img {
    width: 129px;
    height: 56px;
  }
  .section__header {
    margin-bottom: 35px;
  }
  .section__title {
    span {
      display: inline-block;
    }
  }
  .tns-ovh {
    overflow: unset;
  }
}

.success {
  @include media(">=tablet") {
    max-width: 430px;
  }
  @include media(">=big_desktop") {
    max-width: 420px;
  }
  &__item {
    position: relative;
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include media(">=tablet") {
      margin-bottom: 24px;
    }
  }
  &__span {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: $manatee;
    font-weight: 500;
    font-style: italic;
    padding-left: 39px;
    @include media(">=tablet") {
      font-size: 16px;
      line-height: 20px;
    }
    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: 0;
      width: 24px;
      height: 24px;
      background: $pelorous url(../images/svg/check.svg) 4px -5px no-repeat;
    }
  }
}
