.customers {
  background-color: $alabaster_rgba;
  overflow: hidden;
  &__list-wrapper {
    position: relative;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    @include media(">=big_desktop") {
      position: static;
      left: 0;
      width: 100%;
      margin-left: 0;
      overflow-x: hidden;
    }
    .container {
      @include media(">=big_desktop") {
        padding: 0;
      }
    }
  }
  &__list {
    @include media(">=big_desktop") {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow-y: hidden;
    }
  }
  &__item {
    display: inline-block;
    @include media(">=big_desktop") {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
  &__img {
    width: 129px;
    height: 56px;
  }
  .tns-ovh {
    overflow: unset;
  }
}
