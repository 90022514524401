.numbers {
  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__item {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    @include media(">=tablet") {
      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }
    @include media(">=big_desktop") {
      margin-bottom: 56px;
      &:nth-last-child(-n + 3) {
        margin-bottom: 0;
      }
    }
  }
  .section__title {
    margin-bottom: 0;
  }
}

.about-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include media(">=tablet") {
    max-width: 255px;
  }
  @include media(">=big_desktop") {
    max-width: 315px;
  }
  &__title {
    color: $manatee;
    font-size: 24px;
    line-height: 32px;
    font-style: italic;
    font-weight: 500;
    @include media(">=big_desktop") {
      font-size: 40px;
      margin-bottom: 2px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 20px;
    @include media(">=big_desktop") {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__icon {
    width: 64px;
    height: 64px;
    border: 2px solid $manatee;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 22px;
    flex: 0 0 auto;
    @include media(">=tablet") {
      margin-right: 24px;
    }
    @include media(">=big_desktop") {
      margin-right: 16px;
      width: 88px;
      height: 88px;
    }
  }
}
