.suggest {
  &__figure {
    margin: 0;
    width: 180px;
  }
  &__figcaption {
    padding: 0 5px;
  }
  &__list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    @include media(">=tablet") {
      justify-content: flex-start;
    }
  }
  &__item {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
    @include media(">=tablet") {
      padding: 0 50px;
      margin-bottom: 45px;
      &:nth-last-child(-n + 3) {
        margin-bottom: 0;
      }
    }
    @include media(">=big_desktop") {
      padding: 0 60px;
      margin-bottom: 50px;
      &:nth-last-child(-n + 4) {
        margin-bottom: 0;
      }
    }
  }
  &__header {
    position: relative;
    margin-bottom: 23px;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 10px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: $pelorous url(../images/svg/check.svg) 50% 50% no-repeat;
    }
  }
  &__image {
    width: 180px;
    height: 180px;
    border: 1px solid $manatee;
    border-radius: 50%;
    overflow: hidden;
  }
  &__span {
    display: block;
    color: $manatee;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-style: italic;
    text-align: center;
    span {
      display: block;
    }
    @include media(">=tablet") {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__circle {
    position: relative;
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid $honey_flower;
    border-radius: 50%;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      background-color: $white_lilac;
      border-radius: 50%;
      z-index: -1;
      opacity: 0;
      transition: 0.15s;
    }
    &:hover {
      .suggest__arrow {
        &::before {
          right: -50px;
        }
      }
      &::after {
        opacity: 1;
      }
    }
    &:active {
      &::after {
        width: 130px;
        height: 130px;
        background-color: $active_circle;
      }
    }
  }
  &__link-text {
    color: $honey_flower;
    font-size: 14px;
    line-height: 16px;
  }
  &__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    width: 50px;
    height: 23px;
    transform: translateY(-50%);
    &::before {
      content: "";
      position: absolute;
      right: -30px;
      top: 50%;
      width: 50px;
      height: 23px;
      transform: translateY(-50%);
      background-image: url(../images/svg/arrow-right.svg);
      transition: 0.15s;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 20px;
      height: 1px;
      transform: translateY(-50%);
      background-color: $honey_flower;
    }
  }
}
