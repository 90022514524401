.producer {
  background-color: $alabaster_rgba;
  &__slider {
    position: relative;
  }
  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 8px;
    &:nth-child(2n) {
      @include media(">=tablet") {
        margin-left: 36px;
      }
      @include media(">=big_desktop") {
        margin-left: 58px;
        margin-bottom: 16px;
      }
    }
    @include media(">=big_desktop") {
      margin-bottom: 16px;
    }
  }
  &__item {
    margin-right: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__link {
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
    }
    &::before {
      top: 10px;
      right: 10px;
      background-image: url(../images/producer/link.svg);
      width: 12px;
      height: 12px;
      z-index: 1;
    }
    &::after {
      top: 4px;
      right: 4px;
      width: 24px;
      height: 24px;
      background-color: $white_lilac;
      z-index: 0;
      opacity: 0;
      visibility: hidden;
      transition: 0.15s;
    }
    &:hover,
    &:active {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
    &:active {
      &::after {
        border-radius: 50%;
        background-color: $link_after_active;
      }
    }
  }
  &__img {
    width: 129px;
    height: 56px;
  }
  .tns-ovh {
    height: 283px;
    @include media(">=tablet") {
      height: 201px;
    }
    @include media(">=big_desktop") {
      height: 272px;
    }
  }
  .rectangle {
    width: 168px;
    height: 74px;
    @include media(">=tablet") {
      width: 192px;
      height: 96px;
    }
  }
  .slider {
    &__controls {
      bottom: 20px;
      flex-direction: column;
      @include media(">=tablet") {
        top: 0;
        bottom: auto;
      }
    }
    &__button {
      margin-bottom: 8px;
      @include media(">=tablet") {
        margin-bottom: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
