.represent {
  background-color: $white_lilac_rgba;
  overflow: hidden;
  &__title {
    @include media(">=big_desktop") {
      max-width: 1025px;
    }
  }
  .tabs {
    @include media(">=big_desktop") {
      margin-left: 108px;
    }
  }
}
