.button {
  font-family: "Gotham Pro", sans-serif;
  border: 0;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: $honey_flower;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
  &:disabled,
  &[disabled] {
    .angle-left {
      fill: $button_disabled;
    }
  }
  @include media(">=tablet") {
    width: auto;
  }
  &--partner,
  &--send {
    background-color: $white;
    border: 1px solid $honey_flower;
  }
  &--partner {
    padding: 15px 38px;
    &:hover {
      color: $white;
      background-color: $honey_flower;
    }
  }
  &--send {
    padding: 15px 57px;
    &:hover {
      color: $white;
      background-color: $honey_flower;
    }
  }
  &--ripple {
    position: relative;
    z-index: 2;
  }
}

.ripple-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  transform: scale(1);
  z-index: 2;
  cursor: pointer;
}

.ripple-effect {
  position: absolute;
  border-radius: 50%;
  animation: rippleEffect 0.5s forwards;
  opacity: 0;
  transition: 1s;
}

.light {
  background-color: $ripple-light;
}

.gray {
  background-color: $ripple-gray;
}

@keyframes rippleEffect {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  60% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
