// Normalize Styles
@import "node_modules/include-media/dist/_include-media";
@import "node_modules/normalize.css/normalize";
@import "node_modules/sal.js/dist/sal";

// Settings styles
@import "./settings/fonts";
@import "./settings/colors";
@import "./settings/breakpoints";

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  min-width: 320px;
  font-family: "Gotham Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  overflow-x: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $alabaster;
  margin: 0;
}

p {
  color: $mine_shaft;
  margin: 0;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.container {
  max-width: 320px;
  margin: 0 auto;
  padding: 0 30px;
  @include media(">=tablet") {
    max-width: 620px;
  }
  @include media(">=big_desktop") {
    max-width: 1290px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: $overlay;
  z-index: 99;
  transition: 0.2s;
  &--visible {
    opacity: 1;
    visibility: visible;
  }
}

.scroll-lock {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.link {
  position: relative;
  color: $honey_flower;
  display: inline-block;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    bottom: 5px;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: $pelorous;
    transition: 0.1s;
    z-index: -1;
  }
  &:hover {
    &::after {
      height: 3px;
    }
  }
}

.circle {
  position: relative;
  &::after {
    content: "";
    position: relative;
    right: -3px;
    bottom: 15px;
    width: 5px;
    height: 5px;
    background-color: $pelorous;
    border-radius: 50%;
    display: inline-block;
    @include media(">=tablet") {
      width: 7px;
      height: 7px;
      right: -3px;
      bottom: 22px;
    }
    @include media(">=desktop") {
      width: 9px;
      height: 9px;
    }
    @include media(">=big_desktop") {
      right: -3px;
      bottom: 30px;
    }
  }
  &--more-rows {
    &::after {
      // top: auto;
      // right: -10px;
      // bottom: 20px;
      @include media(">=tablet") {
        // right: -15px;
        // bottom: 25px;
      }
      @include media(">=big_desktop") {
        // bottom: 35px;
      }
    }
  }
}

.rectangle {
  width: 168px;
  height: 74px;
  border: 1px solid $white_lilac;
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include media(">=tablet") {
    width: 176px;
    height: 88px;
  }
  @include media(">=big_desktop") {
    width: 192px;
    height: 96px;
  }
}

.left-line {
  position: relative;
  padding-left: 8px;
  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 2px;
    height: 4px;
    background-color: $pelorous;
  }
}

.main-planar {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
      90deg,
      transparent -40px,
      transparent 4px,
      #eef2fa,
      #eef2fa 5px
    );
    z-index: -1;
    @include media(">=tablet") {
      background: repeating-linear-gradient(
        90deg,
        white -10px,
        white 57px,
        #eef2fa,
        #eef2fa 58px
      );
    }
    @include media(">=big_desktop") {
      background: repeating-linear-gradient(
        90deg,
        white -60px,
        white 44px,
        #eef2fa,
        #eef2fa 45px
      );
    }
  }
}

.underline {
  border-bottom: 1px solid $white_lilac;
}

[data-sal][data-sal-duration] {
  @include media("<tablet") {
    transition-delay: 0s;
    transition-duration: 0s;
  }
}

._link {
  &:hover {
    text-decoration: underline;
  }
}

// Import Modules
@import "../_modules/header/header";
@import "../_modules/button/button";
@import "../_modules/portal/portal";
@import "../_modules/burger/burger";
@import "../_modules/advantages/advantages";
@import "../_modules/svg-sprite/svg-sprite";
@import "../_modules/represent/represent";
@import "../_modules/tabs/tabs";
@import "../_modules/suggest/suggest";
@import "../_modules/producer/producer";
@import "../_modules/tiny-slider/tiny-slider";
@import "../_modules/distributor/distributor";
@import "../_modules/capability/capability";
@import "../_modules/customers/customers";
@import "../_modules/control/control";
@import "../_modules/numbers/numbers";
@import "../_modules/footer/footer";
@import "../_modules/top-nav/top-nav";
@import "../_modules/section/section";
@import "../_modules/docs/docs";
