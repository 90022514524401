@import "../../_scripts/tiny-slider/src/tiny-slider";

.advantages {
  position: relative;
  overflow: hidden;
  background-color: $mirage;
  z-index: 1;
  &__slider {
    position: relative;
  }
  &__slide {
    position: relative;
    width: 100vw;
    height: 400px;
    background-color: $mirage;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 30px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(0, 6, 23, 0.6),
        rgba(0, 6, 23, 0.6)
      );
    }
    @include media(">=tablet") {
      height: 370px;
      padding-top: 0;
    }
    @include media(">=big_desktop") {
      height: 625px;
    }
    .container {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      @include media(">=tablet") {
        align-items: center;
      }
    }
  }
  &__content {
    @include media(">=big_desktop") {
      max-width: 608px;
    }
  }
  .section {
    &__title,
    &__text {
      color: $alabaster;
    }
    &__title,
    &__text,
    &__button {
      position: relative;
      transition: 0.35s;
      transition-delay: 0.2s;
      opacity: 0;
      @include media(">=tablet") {
        transition: 0.55s;
        transition-delay: 0.2s;
      }
    }
    &__title {
      left: 10px;
      @include media(">=tablet") {
        left: 30px;
      }
    }
    &__text {
      left: 20px;
      @include media(">=tablet") {
        left: 60px;
      }
    }
    &__button {
      left: 30px;
      @include media(">=tablet") {
        left: 90px;
      }
    }
  }
  .tns-slide-active {
    .section {
      &__title {
        left: 0;
        opacity: 1;
      }
      &__text {
        left: 0;
        opacity: 1;
      }
      &__button {
        left: 0;
        opacity: 1;
      }
    }
  }
  .slider__button--prev {
    margin-right: 8px;
    @include media(">=tablet") {
      margin-right: 16px;
    }
  }
}

[data-action] {
  display: none;
}
